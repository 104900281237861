import React, { useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import SliderHeader from '../SliderHeader'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { toggleNoteSlider } from '../../../store/slices/sliderSlice'
import { Button } from '../../buttons/button'
import { useFormik } from 'formik'
import {
  noteInitialState,
  notevalidationSchema,
} from '../../../functionality/note'
import { addDocData, updateDocData } from '../../../store/slices/prescriptionSlice'
import FormErrorMessage from '../shared/FormErrorMessage'

const NoteSlider = () => {
  const dispatch = useAppDispatch()
  const noteState = useAppSelector((state) => state.slider.noteSlider);
  const note_type = typeof noteState?.data === "string" ? noteState?.data : noteState?.data?.type;
  const note_data = typeof noteState?.data === "object" ? noteState?.data?.data : null;


  const toggle = () => {
    dispatch(toggleNoteSlider(null));
  };

  const formik = useFormik({
    initialValues: {
      text: note_data?.text || "",
    },
    validationSchema: notevalidationSchema,
    onSubmit: async (values) => {
      let data: any = { [note_type]: values.text };

      if (note_data?._id) {
        await dispatch(updateDocData(note_type, { "text": values.text, _id: note_data._id }));
      } else if (note_type === 'followup_notes') {
        await dispatch(updateDocData(note_type, { 'followup_notes': values.text }));
      } else {
        if (note_type === 'followup_notes') {
          data = { followup_notes: values.text }
        } else {
          data = { "text": values.text }
        }
        await dispatch(addDocData(note_type, data));
      }

      toggle();
    },
  });

  // Fix: Set initial values when `note_type` or `note_data` changes
  useEffect(() => {
    if (note_type === 'followup_notes') {
      formik.setValues({ text: note_data || "" });
    } else if (note_type) {
      formik.setValues({ text: note_data?.text || "" });
    }
  }, [note_type, note_data]);

  return (
    <Dialog.Panel className="pointer-events-auto h-full w-screen max-w-lg">
      <div className="flex h-full flex-col justify-center overflow-y-scroll bg-gray-100 py-6 shadow-xl">
        {/* <SliderHeader onClose={() => {}} title={undefined} /> */}
        <div className="mt-6 flex flex-col px-4 sm:px-6">
          <div className="flex items-center justify-between">
            <span className="text-2xl font-semibold">
              {note_type === 'notes'
                ? 'Notes :' : note_type === 'followup_notes'
                  ? 'Follow Up Note :' : note_type === 'diagnosis'
                    ? 'Diagnosis :' : 'Handover Notes :'
              }
            </span>
            <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
              <XIcon onClick={toggle} className="h-3 w-3" />
            </span>
          </div>
          <div className='text-right'>
            <textarea
              name="text"
              value={formik.values.text}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              className="mt-3 h-28 w-full rounded-md border-2 border-gray-300 text-sm pl-2 py-2"
            ></textarea>
            <span>{formik.values.text.length}/ 500</span>
          </div>

          <FormErrorMessage
            touched={formik.touched.text}
            error={formik.errors.text}
          />
          <Button
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            className="mx-auto mt-6 w-40 text-lg"
          >
            {formik.isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </div>
    </Dialog.Panel>
  )
}

export default NoteSlider
